.mailingCounter
{
	margin-bottom: 1rem;
}

.mailingCounterEntry
{
	display: inline-block;
	margin:  0 10px;
	text-align: center;
}

.mailingCounterEntry:first-of-type
{
	margin-left: 0;
}

.mailingCounterEntry:last-of-type
{
	margin-right: 20px;
}

.mailingCounterEntry p
{
	border: 1px solid #aaa;
	border-radius: 3px;
	margin-top: 5px;
	padding: 5px;
	width: 150px;
}

.mailingCounterEntry p input
{
	text-align: center;
}

.mailingCounterEntry p input:read-only
{
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	cursor: default;
}

.mailingCounterButton:not(:last-of-type)
{
	margin-right: 20px;
}
