.App
{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#mainContent
{
	display: flex;
	width: 100%;
	flex: 1;
	position: relative;
}

.content
{
	flex: 1;
	position: relative;
	z-index: 0;
}
