.sideMenuContainer
{
	padding: 10px 25px;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	justify-content: space-between;
}

.sideMenuHeader
{
	color: rgb(26, 26, 26);
}

.sideMenu
{
	list-style: none;
	margin: 0 0 40px;
	padding: 0;
}

.sideMenuListItemLink
{
	color: #2b3e50;
	transition-property: color, text-shadow;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
}

.sideMenuListItemLink:hover
{
	text-decoration: none;
	color: #20303f;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.sidebar
{
	background-color: #f8f9fa;
	position: absolute;
	width: 100%;
	z-index: 1;
	left: -100%;
	top: 0;

	transition: left 200ms ease-in-out;
}

.sidebar.active
{
	left: 0;
}

@media (min-width: 992px)
{
	.sidebar
	{
		width: auto;
		position: static;
	}
}
