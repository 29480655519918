body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.dropzone-upload-form {
  vertical-align: center;
  display: inline-block;
  width: 100%;
  height: 50%;
}

.main-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dropzone-container
{
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dropzone2
{
    flex: 1 auto;
    height : 50%;
    color: black;
    border-color: lightskyblue;
    border-style: dashed;
    margin:30px auto;
    border-radius: 5px;
    background-color: #edf6ff;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dropzone2-disabled{
    color: darkgray;
    border-color: darkgray;
    flex: 1 auto;
    height : 50%;
    border-style: dashed;
    margin:30px auto;
    border-radius: 5px;
    background-color: #e4edf6;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brandName{
    color: white!important;
}

.greyBackground{
    background-color: #f8f9fa;
    width: 100%;
    height: 60px;
}

.link{
    text-align: left;
    float: left;
    margin: 0px;
    padding: 0px;
}

.logo{
	max-height: 50px;
}

.custom-container{
    padding: 0px;
    text-align: right;
}

.logout{
    display: inline-block;
}

.inputPartner{
    align-self: center;
    margin: 0 auto;
}

.iconContainer
{
    display:inline-block;
    vertical-align: middle;
}

.textInsideDropzone{
    display: inline-block;
    vertical-align: middle;
}

.whiteLink{
    color: white;
}

.icon-larger{
    margin-bottom: 10px;
}

.solrCloudPanel{
    background-color: darkorange;
}

.alertUploadFile{
    margin-top: 15px;
}

.clickable
{
    cursor: pointer;
}

.bg-darkblue
{
	background-color: #0e3da1;
}

.bg-lime
{
	background-color: rgb(90, 199, 18);
}

.dropdown-menu {
  max-height: 20em;
  overflow: auto;
}

.query-result-table td {
  vertical-align: middle;
}

.query-result-table td.dataset {
  word-break: break-all;
  max-width: 300px;
}

.query-builder .token {
  min-width: 50vw;
  max-width: 370px;
}

.query-builder .close {
  position: absolute;
  top: -1rem;
  right: 0;
  padding: 5px;
}

.query-bracket-box {
  background: rgba(200, 200, 200, 0.25);
  padding: 2px 2px 2px 40px;
}

.provider-logo {
  height: 2em;
}

@media (min-width: 768px) {
  .query-builder .token {
    min-width: 30vw;
  }
}

@media (min-width: 992px) {
  .query-builder .token {
    min-width: 20vw;
  }
}

@media (min-width: 1200px) {
  .query-builder .token {
    min-width: 15vw;
  }
}
